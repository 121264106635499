<template>
  <div
    class='background-style text-white py-4 px-4 lg:py-12 lg:px-8'
    :style='`${backgroundStyle}`'>
    <ul class='text-xs sidebar-navigation-links flex flex-col lg:sticky lg:top-24'>
      <li class='mb-2 lg:mb-6'>
        <router-link :to='{ name: "Sponsors" }'  class='py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
          <span class='text-base'>List Of Sponsors</span>
        </router-link>
      </li>
      <li class=''>
        <router-link :to='{ name: "Sponsorship" }'  class='py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
          <span class='text-base'>Sponsorship Application</span>
        </router-link>
        <slot></slot>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SidebarPageNavigation',
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    backgroundStyle () {
      return (this.customBackgroundStyle) ? this.customBackgroundStyle : `background-color: ${this.eventMainThemeColor};`
    }
  }
}
</script>

<style lang='scss' scoped>

.background-style {
  background: transparent; 
  width: 100%;
}

.sidebar-navigation-links .router-link-active {
  @apply font-bold;
}

.sidebar-navigation-links img {
  @apply w-px;
}

.sidebar-navigation-links .router-link-active img,
.sidebar-navigation-links a:hover img {
  @apply opacity-100 w-6;
}
  
@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}


</style>


