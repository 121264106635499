<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-16'>
    <sidebar-page-navigation class='flex-shrink-0'>
      <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-100 text-sm'>
        <li>
          <a class='hover:underline cursor-pointer'
            v-scroll-to="{ 
              el: '#domestic-pharmaceutical-company',
              offset: -100
            }">
            국내 기업 (제약/의료기기 회사, 출판)
          </a>
        </li>
        <li class='mt-1'>
          <a class='hover:underline cursor-pointer'
            v-scroll-to="{ 
              el: '#domestic-general-company',
              offset: -100
            }">
            국내 기업 (일반회사)
          </a>
        </li>
        <li class='mt-1'>
          <a class='hover:underline cursor-pointer'
            v-scroll-to="{ 
              el: '#foreign-company',
              offset: -100
            }">
            Foreign Company
          </a>
        </li>
      </ul>
    </sidebar-page-navigation>
    <div class='pt-12 px-4 lg:px-0'>
      <h1 class='text-3xl lg:text-4xl font-bold w-full'>Sponsorship Application</h1>
      <div class='mt-8 lg:mt-24'>
        <div class='flex flex-col lg:flex-row justify-start items-start gap-y-2'>
          <h2 class='text-2xl lg:text-3xl font-semibold w-full lg:w-1/3'>국내 기업</h2>
          <div class='w-full lg:w-2/3 pl-0 lg:pl-6'>
            <div class='flex flex-row justify-end gap-x-2 text-center'>
              <a href='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/sponsor-files/%E1%84%80%E1%85%AE%E1%86%A8%E1%84%8C%E1%85%A6%E1%84%92%E1%85%A1%E1%86%A8%E1%84%89%E1%85%AE%E1%86%AF%E1%84%83%E1%85%A2%E1%84%92%E1%85%AC+%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%80%E1%85%A9%E1%86%BC%E1%84%86%E1%85%AE%E1%86%AB_v1.pdf'
                target='_blank'
                :style='`background-color: ${eventMainThemeColor};`'
                class='text-xs lg:text-sm text-white font-semibold px-4 py-2 w-1/3 lg:w-52 flex flex-row justify-center items-center'>
                국제학술대회 인정공문
              </a>
            </div>
          </div>
        </div>
        <div class='mt-8'>
          <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='mt-2 mb-8'>
              귀사의 일익번창과 무궁한 발전을 진심으로 기원합니다.
            </li>
            <li class='mt-2 mb-8'>
              본 학회 2022년도 제66차 국제학술대회가 <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>2022년 10월 13일(목)~15(토), 3일간 스위스그랜드호텔 컨벤션센터와 온라인 웹사이트</span>에서 병행 개최됩니다.
            </li>
            <li class='mt-2 mb-8'>
              이번 국제학술대회는 하이브리드 개최방식이기는 하나 대면 참석이 기본으로 지방 회원 또는 부득이한 경우를 제외하고 모두 대면 참석할 것으로 기대됩니다. 따라서 코로나19 이전과 같이 전체 3,500여명 이상의 참석규모를 예상하고 해외초청연자 역시 50명 이상 방문하며 알차고 풍성한 프로그램을 준비하고 있습니다.
            </li>
            <li class='mt-2 mb-8'>
              이번 국제학술대회 전시 참여를 희망하시는 업체는 첨부된 제안서의 등급별 세부사항을 확인해 주시고 참여 등급을 정하신 후 신청서를 작성하시어 <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>9월 19일(월)</span>까지 보내주시기 바라며 모든 부스 신청은 런천 또는 스폰서쉽 등급으로 신청하시고 <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>부스 위치는 등급별 혜택에 따라 우선 선정 또는 임의 배정됩니다.</span>
            </li>
            <li class='mt-2 mb-8'>
              위 내용에 대한 추가 문의는 학회 사무국(02-780-2765)으로 연락주시고 2022 대한정형외과학회 제66차 국제학술대회가 안전하고 성황리에 개최될 수 있도록 귀사의 전폭적인 협조와 참여 부탁드립니다.
            </li>
          </ul>
          <P class='mt-4 ttext-sm lg:text-base w-full'>
            * 대한의학회 국제학술대회 인정공문은 8월중 국제학술대회 홈페이지에서 다운로드 받을 수 있도록 공지할 예정입니다.
          </P>
          <P class='mt-4 ttext-sm lg:text-base w-full'>
            ** 전시 신청 후 부득이하게 취소해야 할 경우에는 반드시 학회로 취소 메일을 보내주셔야 환불이 가능하고 <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>미입금일 경우에도 학회에 피해가 발생하지 않도록 반드시, 꼭 취소 회신을 부탁드립니다.</span>
          </P>
          <P class='mt-4 ttext-sm lg:text-base w-full'>
            *** <span class='font-semibold text-red-500'>환불 가능한 취소마감일은 10월 4일(화)이고 이후 취소는 가능하나 환불은 불가합니다.<br>
            (신청 후 무단 불참하는 회사는 향후 학회 참여가 불가한 점 참고 부탁드립니다.)</span>
          </P>
        </div>
        <div class='mt-8 lg:mt-16' id='domestic-pharmaceutical-company'>
          <div class='flex flex-col lg:flex-row justify-start items-start gap-y-2'>
            <h3 class='text-xl lg:text-2xl font-medium w-full lg:w-1/3'>의료부분 (제약/의료기기 회사, 출판)</h3>
            <div class='w-full lg:w-2/3 pl-0 lg:pl-6'>
              <div class='flex flex-row justify-end gap-x-2 text-center'>
                <a href='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/sponsor-files/Sponsorship+Info_%E1%84%8C%E1%85%A6%E1%84%8B%E1%85%A3%E1%86%A8%E1%84%8B%E1%85%B4%E1%84%85%E1%85%AD%E1%84%80%E1%85%B5%E1%84%80%E1%85%B5_v2.pdf'
                  target='_blank'
                  :style='`background-color: ${eventMainThemeColor};`'
                  class='text-xs lg:text-sm text-white font-semibold px-4 py-2 w-1/3 lg:w-52 flex flex-row justify-center items-center'>
                  Sponsorship Info
                </a>
                <a href='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/sponsor-files/Sponsorship+Application_v2.docx'
                  target='_blank'
                  :style='`background-color: ${eventMainThemeColor};`'
                  class='text-xs lg:text-sm text-white font-semibold px-4 py-2 w-1/3 lg:w-52 flex flex-row justify-center items-center'>
                  Sponsorship Application
                </a>
                <a href='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/sponsor-files/Proposal+For+Sponsorship_v2.pdf'
                  target='_blank'
                  :style='`background-color: ${eventMainThemeColor};`'
                  class='text-xs lg:text-sm text-white font-semibold px-4 py-2 w-1/3 lg:w-52 flex flex-row justify-center items-center'>
                  Proposal For Sponsorship
                </a>
              </div>
            </div>
          </div>
          <h4 class='text-lg font-medium mt-8 mb-2 px-2'>Luncheon Symposium 혜택</h4>
          <table class='mt-2 mb-8 sponsorship-table border-t border-l text-sm lg:text-base w-full'>
            <thead class='bg-gray-100'>
              <tr>
                <th class='w-1/4'>등급</th>
                <th class='w-3/4'>세부 내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Luncheon<br>(3천만원, VAT별도)</th>
                <td>
                  <ul class='list-disc list-inside text-gray-600 text-sm lg:text-base leading-6'>
                    <li class='mt-2 mb-6'>
                      원하는 분야의 회의장에서 Symposium 진행(30분 이내)하면서 점심식사<br>
                      <span class='ml-2'>- Diamond, Platinum 등급 분야 선정 후 선착순 신청으로 분야가 선정됨</span><br>
                      <span class='ml-2'>- 좌장과 연자는 회사에서 선정하되 학회 협조가 필요한 경우 일정 한달 전까지 요청</span><br>
                      <span class='ml-2'>- 좌장, 연자료는 학회에서 일괄 10만원 지급</span>
                    </li>
                    <li class='mt-2 mb-6'>
                      <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>2부스 기본 제공, 무료 등록 2명</span>
                    </li>
                    <li class='mt-2 mb-6'>
                      명찰 로고 삽입, 학회장 각종 현황판 로고 삽입
                    </li>
                    <li class='mt-2 mb-6'>
                      학술대회 홈페이지와 온라인 플랫폼 메인 <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>무료 배너 광고</span><br>
                      <span class='ml-5'>(회사 URL 링크, 홍보 동영상 또는 홍보자료 업데이트)</span>
                    </li>
                    <li class='mt-2 mb-6'>
                      프로그램 북 내지 광고 무료
                    </li>
                    <li class='mt-2 mb-6'>
                      각 회의장 청중 예상 인원은 150~400명, 온라인 시청 인원은 각 채널별 300-500명 이상 예상
                    </li>
                    <li class='mt-2 mb-6'>
                      학회장 3층(의료기기), 4층(제약회사) Lobby에 부스 위치 임의 배정
                    </li>
                    <li class='mt-2 mb-6'>
                      10/13(목) 6개 – 골절, 슬관절, 고관절, 족부족관절, 견주관절, 미세수술
                    </li>
                    <li class='mt-2 mb-2'>
                      10/14(금) 5개 – International Sessions, 슬관절, 수부, 기초연구, 소아
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <h4 class='text-lg font-medium mt-8 mb-2 px-2'>Sponsorship 등급별 혜택</h4>
          <table class='mt-2 mb-8 sponsorship-table border-t border-l text-sm lg:text-base w-full'>
            <thead class='bg-gray-100'>
              <tr>
                <th class='w-1/4'>등급</th>
                <th class='w-3/4'>세부 내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Diamond<br>(1억5천만원, VAT별도)</th>
                <td>
                  <ul class='list-disc list-inside text-gray-600 text-sm lg:text-base leading-6'>
                    <li class='mt-2 mb-6'>
                      메인 스폰서 타이틀, 6부스, 런천 심포지엄, 무료 등록 6명, 회장 만찬 초대 4명<br>
                      <span class='ml-2 font-semibold' :style='`color: ${eventMainThemeColor};`'>- 6부스 + 런천 심포지엄 1개 / 4부스 + 런천 심포지엄 2개 중 택일 가능</span>
                    </li>
                    <li class='mt-2 mb-6'>
                      명찰 및 줄 회사 로고 삽입, 학회장 각종 현황판, 현수막, 엑스배너 로고 삽입, 기업 홍보자료 학회장 전체 배포 가능
                    </li>
                    <li class='mt-2 mb-6'>
                      학술대회 홈페이지와 온라인 플랫폼 메인 <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>무료 배너 광고</span><br>
                      <span class='ml-5'>(회사 URL 링크, 홍보 동영상 또는 홍보자료 업데이트)</span>
                    </li>
                    <li class='mt-2 mb-6'>
                      프로그램 북 표4, 초록집 내지 광고 무료
                    </li>
                    <li class='mt-2 mb-2'>
                      학회장 3층(의료기기), 4층(제약회사) Lobby에 부스 위치 우선 선정
                    </li>
                  </ul>
                </td>
              </tr>
              <tr class='bg-gray-100'>
                <th>Platinum<br>(5천만원, VAT별도)</th>
                <td>
                  <ul class='list-disc list-inside text-gray-600 text-sm lg:text-base leading-6'>
                    <li class='mt-2 mb-6'>
                      4부스, 런천 심포지엄, 무료 등록 5명, 회장 만찬 초대 3명<br>
                      <span class='ml-2 font-semibold' :style='`color: ${eventMainThemeColor};`'>- 4부스 + 런천 심포지엄 1개   / 2부스 + 런천 심포지엄 2개 중 택일 가능</span>
                    </li>
                    <li class='mt-2 mb-6'>
                      명찰 및 줄 회사 로고 삽입, 학회장 각종 현황판 로고 삽입
                    </li>
                    <li class='mt-2 mb-6'>
                      학술대회 홈페이지와 온라인 플랫폼 메인 <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>무료 배너 광고</span><br>
                      <span class='ml-5'>(회사 URL 링크, 홍보 동영상 또는 홍보자료 업데이트)</span>
                    </li>
                    <li class='mt-2 mb-6'>
                       프로그램 북 표2, 초록집 내지 광고 무료<br>
                       <span class='ml-5'>(같은 등급이 복수일 경우 프로그램 북 광고 위치가 변경될 수 있음)</span>
                    </li>
                    <li class='mt-2 mb-2'>
                       학회장 3층(의료기기), 4층(제약회사) Lobby에 부스 위치 우선 선정
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th>Gold, Gold+<br>(4천만원, VAT별도)</th>
                <td>
                  <ul class='list-disc list-inside text-gray-600 text-sm lg:text-base leading-6'>
                    <li class='mt-2 mb-6'>
                      4부스 제공(Gold), 2부스 + 런천심포지엄(Gold+), 무료 등록 3명, 회장만찬 2명 초대
                    </li>
                    <li class='mt-2 mb-6'>
                      명찰 및 줄 로고 삽입, 학회장 각종 현황판 로고 삽입
                    </li>
                    <li class='mt-2 mb-6'>
                      학술대회 홈페이지와 온라인 플랫폼 메인 <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>무료 배너 광고</span><br>
                      <span class='ml-5'>(회사 URL 링크, 홍보 동영상 또는 홍보자료 업데이트)</span>
                    </li>
                    <li class='mt-2'>
                      프로그램 북 표3, 초록집 내지 광고 무료 - Gold<br>
                    </li>
                    <li class='mb-6'>
                      초록집 표4 광고, 프로그램북 내지 광고 무료 - Gold+<br>
                       <span class='ml-5'>(같은 등급이 복수일 경우 프로그램 북 광고 위치가 변경될 수 있음)</span>
                    </li>
                    <li class='mt-2 mb-2'>
                      학회장 3층(의료기기), 4층(제약회사) Lobby에 부스 위치 우선 선정
                    </li>
                  </ul>
                </td>
              </tr>
              <tr class='bg-gray-100'>
                <th>Silver<br>(2천만원, VAT별도)</th>
                <td>
                  <ul class='list-disc list-inside text-gray-600 text-sm lg:text-base leading-6'>
                    <li class='mt-2 mb-6'>
                      2부스 제공(부스위치 우선 선정), 무료 등록 2명
                    </li>
                    <li class='mt-2 mb-6'>
                      학회장 각종 현황판 로고 삽입
                    </li>
                    <li class='mt-2 mb-6'>
                      학술대회 홈페이지와 온라인 플랫폼 메인 <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>무료 배너 광고</span><br>
                      <span class='ml-5'>(회사 URL 링크, 홍보 동영상 또는 홍보자료 업데이트)</span>
                    </li>
                    <li class='mt-2 mb-6'>
                      초록집 내지 광고 무료
                    </li>
                    <li class='mt-2 mb-2'>
                      학회장 3층(의료기기), 4층(제약회사) Lobby에 부스 위치 임의 배정
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th>Bronze<br>(1천만원, VAT별도)</th>
                <td>
                  <ul class='list-disc list-inside text-gray-600 text-sm lg:text-base leading-6'>
                    <li class='mt-2 mb-6'>
                      2부스 제공 (전시장 내 부스위치 우선 선정)
                    </li>
                    <li class='mt-2 mb-2'>
                      학술대회 홈페이지와 온라인 플랫폼 메인 <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>무료 배너 광고</span> (회사 URL 링크 연결)
                    </li>
                  </ul>
                </td>
              </tr>
              <tr class='bg-gray-100'>
                <th>Basic<br>(500만원, VAT별도)</th>
                <td>
                  <ul class='list-disc list-inside text-gray-600 text-sm lg:text-base leading-6'>
                    <li class='mt-2 mb-6'>
                      1부스 제공 (전시장 내 배정, 위치는 입금 순서와 의료기기, 제약 Zone 구분하여 임의 선정)
                    </li>
                    <li class='mt-2 mb-2'>
                      학술대회 홈페이지와 온라인 플랫폼 메인 <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>무료 배너 광고</span> (회사 URL 링크 연결)
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <p class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6 mt-2'>
            * 부스 사이즈 : 1부스 (3m X 3m), 로비 부스 (3m X 2.5m)
          </p>
          <p class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6 mt-2'>
            * 부스를 기본으로 신청할 경우 조립부스를 설치해 드리고 독립으로 신청하면 부스위치만 표시해 드립니다.
          </p>
        </div>
        <div class='mt-8 lg:mt-16' id='domestic-general-company'>
          <div class='flex flex-col lg:flex-row justify-start items-start gap-y-2'>
            <h3 class='text-xl lg:text-2xl font-medium w-full lg:w-1/3'>비의료부분 (일반회사)</h3>
            <div class='w-full lg:w-2/3 pl-0 lg:pl-6'>
              <div class='flex flex-row justify-end gap-x-2 text-center'>
                <a href='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/sponsor-files/sponsor_info_%E1%84%87%E1%85%B5%E1%84%8B%E1%85%B4%E1%84%85%E1%85%AD.pdf'
                  target='_blank'
                  :style='`background-color: ${eventMainThemeColor};`'
                  class='text-xs lg:text-sm text-white font-semibold px-4 py-2 w-1/3 lg:w-52 flex flex-row justify-center items-center'>
                  Sponsorship Info
                </a>
                <a href='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/sponsor-files/Sponsorship+Application_v2.docx'
                  target='_blank'
                  :style='`background-color: ${eventMainThemeColor};`'
                  class='text-xs lg:text-sm text-white font-semibold px-4 py-2 w-1/3 lg:w-52 flex flex-row justify-center items-center'>
                  Sponsorship Application
                </a>
                <a href='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/sponsor-files/Proposal+For+Sponsorship_v2.pdf'
                  target='_blank'
                  :style='`background-color: ${eventMainThemeColor};`'
                  class='text-xs lg:text-sm text-white font-semibold px-4 py-2 w-1/3 lg:w-52 flex flex-row justify-center items-center'>
                  Proposal For Sponsorship
                </a>
              </div>
            </div>
          </div>
          <h4 class='text-lg font-medium mt-8 mb-2 px-2'>Sponsorship 등급별 혜택</h4>
          <table class='mt-2 mb-8 sponsorship-table border-t border-l text-sm lg:text-base w-full'>
            <thead class='bg-gray-100'>
              <tr>
                <th class='w-1/4'>등급</th>
                <th class='w-3/4'>세부 내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Friends<br>(1천만원, VAT별도)</th>
                <td>
                  <ul class='list-disc list-inside text-gray-600 text-sm lg:text-base leading-6'>
                    <li class='mt-2 mb-6'>
                      제약 및 의료기기 회사 또는 정형외과 관련 회사 외 대상<br>
                      <span class='ml-2'>- 각종 재무, 노무, 경영, 세금 관련 컨설팅 회사</span><br>
                      <span class='ml-2'>- 의학 분야 외 제품 또는 기업 홍보 회사</span>
                    </li>
                    <li class='mt-2 mb-2'>
                      1.5부스 제공 (전시장 내 배정, 위치는 임의 배정)
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <p class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6 mt-2'>
            * 부스 사이즈 : 1.5부스 (4.5m X 3m)
          </p>
          <p class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6 mt-2'>
            * 부스를 기본으로 신청할 경우 조립부스를 설치해 드리고 독립으로 신청하면 부스위치만 표시해 드립니다.
          </p>
        </div>
      </div>
      <div class='mt-8 lg:mt-24' id='foreign-company'>
        <div class='flex flex-col lg:flex-row justify-start items-start gap-y-2'>
          <h2 class='text-2xl lg:text-3xl font-semibold w-full lg:w-1/3'>Foreign Company</h2>
          <div class='w-full lg:w-2/3 pl-0 lg:pl-6'>
            <div class='flex flex-row justify-end gap-x-2 text-center'>
              <a href='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/sponsor-files/Foreign_Sponsorship%2BApplication_v1.docx'
                target='_blank'
                :style='`background-color: ${eventMainThemeColor};`'
                class='text-xs lg:text-sm text-white font-semibold px-4 py-2 w-1/3 lg:w-52 flex flex-row justify-center items-center'>
                Sponsorship Application
              </a>
            </div>
          </div>
        </div>
        <div class='mt-8'>
          <h4 class='text-lg font-medium mt-8 mb-2 px-2'>Invitation to Sponsor</h4>
          <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='mt-2 mb-8'>
              The Korean Orthopaedic Association invites you to participate in the 66th Annual Congress
              that will be a great opportunity to show your commitment and support as a sponsor to boost 
              your brand awareness among orthopaedic surgeons from around the world.
            </li>
            <li class='mt-2 mb-8'>
              Approximately 3,500 orthopaedic surgeons will be expected to participate. (including online and offline attendees)
            </li>
          </ul>
        </div>
        <div class='mt-8'>
          <h4 class='text-lg font-medium mt-8 mb-2 px-2'>Sponsor Common Benefit</h4>
          <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='mt-2 mb-8'>
              Display of your company’s name or product name on all conference promotional materials.
            </li>
            <li class='mt-2 mb-8'>
              Placement of your company’s logo or product name on the congress website.
            </li>
            <li class='mt-2 mb-8'>
              Participation in sponsor booth event.
            </li>
            <li class='mt-2 mb-8'>
              Assembly booth provided. (1 table and 2 chairs included)
            </li>
          </ul>
        </div>
        <div class='mt-8'>
          <h4 class='text-lg font-medium mt-8 mb-2 px-2'>Sponsorship Packages Benefits</h4>
          <table class='mt-2 mb-8 sponsorship-table border-t border-l text-sm lg:text-base w-full'>
            <thead class='bg-gray-100'>
              <tr>
                <th class='w-1/4'>Grade</th>
                <th class='w-3/4'>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Bronze<br>(USD 10,000)</th>
                <td>
                  <ul class='list-disc list-inside text-gray-600 text-sm lg:text-base leading-6'>
                    <li class='mt-2 mb-6'>
                      2 booths provided<br>
                      <span class='ml-2'>- Priority of booth place in the exhibition hall</span>
                    </li>
                    <li class='mt-2 mb-6'>
                      <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>Free banner advertisement</span> on the congress website and main online flatform (link to company URL)
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th>Basic<br>(USD 5,000)</th>
                <td>
                  <ul class='list-disc list-inside text-gray-600 text-sm lg:text-base leading-6'>
                    <li class='mt-2 mb-6'>
                      1 booth provided<br>
                      <span class='ml-2'>- Random booth place (Equipment & Pharmaceutical Medicine),  in the exhibition hall</span><br>
                      <span class='ml-2'>- The location of a booth will be allocated on a first-come-first-served basis considering the payment order of the exhibition fee and the contribution to the association.</span>
                    </li>
                    <li class='mt-2 mb-6'>
                      <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>Free banner advertisement</span> on the congress website and main online flatform (link to company URL)
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class='mt-8'>
          <div class='flex flex-col lg:flex-row justify-between justify-start gap-y-2 lg:gap-x-12'>
            <div>
              <h4 class='text-lg font-medium mt-8 mb-2 px-2'>Booth Type</h4>
              <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
                <li class='mt-2 mb-8'>
                  Standard Booth<br>
                  <span class='ml-2'>- Set up the standard booth (3M X 3M)</span><br>
                  <span class='ml-2'>- Place on upper part of signboard, information desk/chair, lighting and power</span>
                </li>
                <li class='mt-2 mb-8'>
                  Independent Booth<br>
                  <span class='ml-2'>- Only provide exhibition space and electricity  </span>
                </li>
              </ul>
            </div>
            <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/sponsor-files/standard_booth.png' class='w-2/3 lg:w-72'> 
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import SidebarPageNavigation from '@/components/koa2022/SidebarPageNavigation.vue'

export default {
  name: 'Sponsorship',
  components: {
    SidebarPageNavigation,
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
  }
}
</script>

<style type='scss' scoped>
  .sponsorship-table tr {
    @apply border-b
  }

  .sponsorship-table td {
    @apply border-r p-1 lg:p-2
  }

  .sponsorship-table th {
    @apply border-r p-1 lg:p-2
  }
</style>
